import {useCallback, useEffect, useState} from 'react'
import logo from './logo.svg';
import './App.css';
import cron from 'node-cron'

function App() {   

  return (

    <div className="App">
      
      <br/> 
      <h3>♥ SOFIA & MANUEL ♥</h3>
      <br/> 
      <iframe className="video" src="https://www.youtube.com/embed/10nMDzy8Ha0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <br/>
      <br/>
      CEREMONIA CIVIL
      <br/>
      Nos encantaría que vivieras con nosotros ete dia tan esperado.
      <br/>
      29.05.2021 
      <br/>
       
      <div class="fb-comments" data-href="https://mtrujillo.com/" data-width="900" data-numposts="30"></div>
    </div>
  );
}

export default App;
