import socketIOClient from "socket.io-client"; 

// const server = `http://18.216.80.191:4001/`
const server = `http://localhost:4001/`

class Socket{
    constructor(){
        this.port = '4001'
        // this.endpoint = `http://18.216.80.191:4001/`
        this.endpoint = `http://localhost:4001/`
        this.socket = socketIOClient(this.endpoint);
    }
}

const socketCli = new Socket()

export default socketCli


