import {useCallback, useEffect, useState} from 'react'
import logo from './logo.svg';
import './App.css';
import cron from 'node-cron'

function App() {  
  const talla = '8' //talla US
  const key = 'yezzy'  
  const key2 = 'dunk'  
  const avoidKey = 'disrupt'
  const avoidKey2 = 'kids'
  const avoidKey3 = '18'
  const avoidKey4 = 'ps'
  const avoidKey5 = 'dunked'


  const randon = true
  const [work,setWork] = useState(true) 
  const [size, setSize] = useState('8.5')
  const [time, setTime] = useState(new Date())  
  const [error, setError] = useState('')

  const shops = {
    lustUrl: 'https://www.lustmexico.com/',
    lacesUrl: 'https://www.laces.mx/',
    n99pUrl: 'https://99store.mx/',
    tfuUrl: 'https://twofeetundr.com/',
    kith: 'https://kith.com/',
    concpts: 'https://cncpts.com/',
    stussy: 'https://www.stussy.com/'
  }

  useEffect(() => {
    let intervalID = setInterval(() => 
      setTime(new Date()) 
    ,1000);
    setInterval(() => {
      setWork(new Date())
    },5000)
  },[])

  useEffect(() => {        
    if(time.getHours() == 11 && time.getMinutes() == 0 && time.getSeconds() == 0) {      
      window.open(`https://www.supremenewyork.com/shop/all/shoes`)
    }
  },[time])

  useEffect(() => {
    storeTask(shops.lustUrl)
    storeTask(shops.lacesUrl)
    storeTask(shops.n99pUrl)
    //storeTask(shops.tfuUrl)
  },[work])

  const changeSize = (size) => {
    setSize(size.target.value)
  }

  const audio = new Audio( 'https://media.geeksforgeeks.org/wp-content/uploads/20190531135120/beep.mp3'); 

  // useEffect(( ) => {
  //   console.log('size',size)
  // },[size])

  const runfunc = () => {    
    window.open(`https://www.nike.com/mx/launch/t/air-jordan-4-union-la-desert-moss?productId=4a65c1c1-d628-556c-af87-2fa01a90f37a&size=${size}`)     
    window.open(`https://www.nike.com/mx/launch/t/dunk-low-dusty-olive?productId=90db8f05-d05b-5fdb-8ae4-01a3b596ebee&size=${size}`)     
  }

  const foundItem = (item, store) => {  
    
    if(randon){
      for(let size of item.variants){                                                           
        if(size.available){        
          console.log(item, store)
          window.open( `${store}cart/${size.id}:1`)
          audio.play() 
          window.close()
          break
        }
      } 
    }
    else{
      for(let j=0;j<item.variants.length;j++){   
        let size = item.variants[j]                                                
        if(size.title.includes(talla) && size.available){  
          console.log(item, store)
          window.open(`${store}cart/${size.id}:1`)     
          audio.play()  
          window.close()     
          break
        }
      }
    }    
  }

  const storeTask = (store) => {    
         
      fetch(`${store}products.json?limit=650`)
      .then(response => response.json())
      .then(data=> {
        console.log(store, data)
        for(let i = 0; i < data.products.length; i++){
          let item = data.products[i]              
          if( (item.title.toLocaleLowerCase().includes(key) || 
            item.title.toLocaleLowerCase().includes(key2) ) &&
            !item.title.toLocaleLowerCase().includes(avoidKey) && 
            !item.title.toLocaleLowerCase().includes(avoidKey2) &&
            !item.title.toLocaleLowerCase().includes(avoidKey3) &&
            !item.title.toLocaleLowerCase().includes(avoidKey4) &&
            !item.title.toLocaleLowerCase().includes(avoidKey5) ){
            //i = data.products.length                    
            foundItem(item, store)         
          }
        }
      })
      .catch(e => setError('Error cierre pagina intente en unos minutos'))             
    
  }

  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <input type="text" id="name" name="name" required
          minlength="4" maxlength="8" size="10" onChange={(dato) => { changeSize(dato) } }/>
        <button onClick={runfunc}>Good Luck!</button>
        <span>
        {
          time.toLocaleString()
        }
        </span>
        <span>
          {
            error
          }
        </span>
      </header>
      
    </div>
  );
}

export default App;
